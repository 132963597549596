export const AppConfig = {
    showFooter: false,
    repeatGuide: false,
    preOrderCard: false,
    showReleaseNotes: false,
    showDomainInstruction: false,
    tawkPlagin: false,
    title: 'NFG Crypto',
    titleSecondary: 'NFG-Crypto',
    mainColor: '#48C8FF',
    identity: 'nfg',
    showNftBlock: false,
};